<template>
  <v-container fluid class="pa-0">
    <v-card>
    <v-img class="black--text align-middle" max-height="220" src="../assets/Contact.png">
        <div class="pa-3">
          <div class="en-title">{{titleEn}}</div>
          <div class="ja-title">{{titleJa}}</div>
        </div>
      </v-img>
    </v-card>
  
    <v-container v-if="!send">
      <v-row align="center" justify="center">
        <v-col cols="11" class="box">
          <form class="center">

            <v-text-field label="お名前" prepend-icon="mdi-account" v-model="name" :error-messages="nameErrors" 
              clear-icon="mdi-close-circle" clearable v-on:input="$v.name.$touch()" v-on:blur="$v.name.$touch()" />
            <v-text-field label="会社名・団体名" prepend-icon="mdi-domain" v-model="com" :error-messages="comErrors" 
              clear-icon="mdi-close-circle" clearable v-on:input="$v.com.$touch()" v-on:blur="$v.com.$touch()"></v-text-field>
            <v-text-field label="部署名" prepend-icon="mdi-" v-model="dep" v-bind:error-messages="depErrors" 
              clear-icon="mdi-close-circle" clearable v-on:input="$v.dep.$touch()" v-on:blur="$v.dep.$touch()"></v-text-field>
            <v-text-field label="職位" prepend-icon="mdi-" v-model="title" :error-messages="titleErrors" 
              clear-icon="mdi-close-circle" clearable v-on:input="$v.title.$touch()" v-on:title="$v.title.$touch()"></v-text-field>
            <v-text-field label="メールアドレス" prepend-icon="mdi-email" v-model="email" v-bind:error-messages="emailErrors" 
              clear-icon="mdi-close-circle" clearable v-on:input="$v.email.$touch()" v-on:blur="$v.email.$touch()"></v-text-field>
            <v-text-field label="電話番号(ハイフン無し)" prepend-icon="mdi-phone" v-model="phone" :error-messages="phoneErrors"      
              clear-icon="mdi-close-circle" clearable v-bind:counter="11" v-on:input="$v.phone.$touch()" v-on:blur="$v.phone.$touch()" />
            <v-text-field label="お問い合せ内容" prepend-icon="mdi-" v-model="Detail" v-bind:error-messages="nameErrors" 
              clear-icon="mdi-close-circle" clearable v-on:input="$v.name.$touch()" v-on:blur="$v.name.$touch()"></v-text-field>

            <v-checkbox v-model="checkbox" v-bind:error-messages="checkboxErrors" dense="dense" required="required"
                        v-on:change="$v.checkbox.$touch()" v-on:blur="$v.checkbox.$touch()">
              <div slot='label'>
                <v-dialog v-model="modal" max-width="650" >
                  <template v-slot:activator="{ on }">
                    <a v-on:click.prevent="dummy" v-on="on">個人情報の取り扱い</a>に同意する
                  </template>                    
                  <v-card>
                    <v-toolbar color="primary" dark="dark">個人情報の取り扱い</v-toolbar>
                    <v-card-text><br/>              
                      <ol>
                        準備中につき、現在お問い合せを受け付けておりません。             
                      </ol>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>                              
                      <v-btn color="green darken-1" text="text" v-on:click="modal = false">閉じる</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-checkbox>
                                    
            <div class="center">
              <v-btn color="primary" class="mr-4" v-on:click="submit" disabled >
                <v-icon left="left">mdi-send</v-icon>送信
              </v-btn>
            </div>

            <v-dialog v-model="dialog" persistent="persistent" max-width="600" >                          
              <v-card>
                <v-toolbar color="primary" dark="dark">休暇申請の確認</v-toolbar>
                <v-card-title class="text-h7">下記内容で申請します。よろしいですか？</v-card-title>
                <v-divider></v-divider><br/>
                <v-card-text>
                  <v-select label="申請種別" v-model="select" disabled="disabled" dense="dense" prepend-icon="mdi-file-document" v-bind:items="items"></v-select>
                  <v-select label="慶弔種別" v-model="type" disabled="disabled" dense="dense" prepend-icon="mdi-file-document" v-bind:items="types"></v-select>
                  <v-text-field v-model="name" label="名前" disabled="disabled" prepend-icon="mdi-account" dense="dense"></v-text-field>
                  <v-text-field v-model="email"  label="メールアドレス" disabled="disabled" prepend-icon="mdi-email" dense="dense"></v-text-field>
                  <v-text-field v-model="phone"  label="電話番号" disabled="disabled" prepend-icon="mdi-phone" dense="dense"></v-text-field>
                  <v-file-input v-model="files" label="証跡" disabled="disabled" prepend-icon="mdi-paperclip" v-if="select === '慶弔休暇申請'" dense="dense"></v-file-input>
                  <v-combobox v-model="dates"  label="休暇申請日" multiple="multiple" chips="chips" dense="dense"
                              small-chips="small-chips" prepend-icon="mdi-calendar" disabled="disabled"></v-combobox>                                    
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text="text" v-on:click="dialog = false">キャンセル</v-btn>
                  <v-btn color="green darken-1" text="text" v-on:click="clear">この内容で送信する</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </form>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="send">
      <v-row align="center" justify="center">
        <v-col cols="12" class="box">
          <form class="center"><b>申請が完了しました。</b><br/><br/>内容に不備がなければこのまま「受付」となります。<br/>
            改めての連絡・返信は致しませんのでご了承ください。<br/>不備がある場合のみ、折り返しご連絡いたします。</form>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>


<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  validations: { com: { required }, dep: { required }, title: { required }, name: { required }, email: { email },          
                  phone: { phoneCheck(){ return this.phone.length != 0 || this.email.length != 0 } },     
                  checkbox: { checked (val) { return val }, },},

  data: () => ({
    titleEn: 'Contact',
    titleJa: 'お問い合わせ',
    com: '',
    dep: '',
    title: '',
    name: '',
    email: '',
    phone: '',
    Detail: '',
    dialog: false,
    modal: false,
    dates: [],
    menu: false,    
    checkbox: false, 
    send: false, 
    files: [],
    textInput: [ { lbl: '貴社名', val: 'com', err: 'comError'}, { lbl: '部署', val: 'dep' }, { lbl: '役職', val: 'title' }, { lbl: 'お名前', val: 'name' }, ],
  }),

  computed: {    
    checkboxErrors () { const errors = []; if (!this.$v.checkbox.$dirty) return errors
      !this.$v.checkbox.checked && errors.push('利用規約への同意が必要です。')
      return errors },
    comErrors () { const errors = []; if (!this.$v.com.$dirty) return errors      
      !this.$v.com.required && errors.push('貴社名は必須項目です。')
      return errors },
    depErrors () { const errors = []; if (!this.$v.dep.$dirty) return errors      
      !this.$v.dep.required && errors.push('部署は必須項目です。')
      return errors },
    titleErrors () { const errors = []; if (!this.$v.title.$dirty) return errors      
      !this.$v.title.required && errors.push('役職は必須項目です。')
      return errors },
    nameErrors () { const errors = []; if (!this.$v.name.$dirty) return errors      
      !this.$v.name.required && errors.push('名前は必須項目です。')
      return errors },
    emailErrors () { const errors = []; if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('メールアドレスの形式ではありません。')
      this.phone.length == 0 && this.email.length == 0 && errors.push('「メールアドレス」か「電話番号」のどちらかは必須項目です。')
      return errors },    
    phoneErrors () { const errors = []; if (!this.$v.phone.$dirty) return errors
      this.phone.length == 0 && this.email.length == 0 && errors.push('「メールアドレス」か「電話番号」のどちらかは必須項目です。')
      return errors },    
  },

  methods: {
    dummy(){ },
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.dialog = true   
      }      
    },
    clear () {
      this.$v.$reset()
      this.name = ''
      this.email = ''
      this.phone = ''
      this.select = ''
      this.dates = []
      this.checkbox = false
    },
  },
}
</script>
<style >
  .middle{ display: flex; align-items: center; }
  .en-title{ 
    font-size: xxx-large;
    color: lavender;
    line-height: initial;
  }
  .ja-title{
    font-size: x-large;
    font-weight: bold;
  }
  .center{ text-align: center;}
</style>