<script>
  export default {
    data: () => ({
      name: 'houston',
      menus: ['ホーム','会社概要','お問い合わせ'],
      select: null,
      visible: true
    }),
    methods: {
      parentMethod: function(i) {
        this.select = i;
        this.$emit('parentMethod', this.select)
      },
      handleResize: function() {
        this.visible = window.innerWidth > 768;
      }
    },
    mounted: function () { 
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    onBeforeUnmount: function () { window.removeEventListener('resize', this.handleResize) },
  }
</script>

<template>
  <v-app-bar color="white" app elevate-on-scroll>
    <v-row no-gutters>
      <v-col class="middle">
        <img width="105" class="menu" src="../assets/logo2.jpeg" @click="parentMethod(null)">
      </v-col>
            
      <v-col class="right">
        <div class="d-flex justify-end">
          <div class="pa-3 middle menu" @click="parentMethod(i)" v-for="(n, i) in menus" :key="i">
            <div v-if="visible">{{ n }}</div>
          </div>

          <v-menu transition="slide-y-transition" bottom v-if="!visible">
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon  v-bind="attrs" v-on="on"/>
          </template>
          <v-list>
            <v-list-item v-for="(menu, i) in menus" :key="i" link>
              <v-list-item-title @click="parentMethod(i);">{{ menu }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
    </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style>
  .none{ display: none; }
  .right{ text-align: right; }  
  .hf{ height: 100%; }
  .middle{ display: flex; align-items: center; }
  .menu:hover{ background-color: rgba(44, 95, 226, 0.2);}
</style>