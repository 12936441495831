<template>
  <v-app>
    <SiteHeader @parentMethod="headerVal" v-if="true"/>
    <v-main>

      <SiteHome v-if="select == null || select == 0"/>
      <CompanyInfo v-if="select == 1"/>
      <InquiryForm v-if="select == 2"/>
  
    </v-main>
    <SiteFooter/>
  </v-app>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import InquiryForm from './components/InquiryForm.vue'
import SiteHome from './components/SiteHome.vue'
import CompanyInfo from './components/CompanyInfo.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteHome,
    InquiryForm,
    CompanyInfo,
    SiteFooter,
  },
  data: () => ({
    model: null,
    select: null,
  }),
  methods: {
    headerVal (s) { this.select = s; }
  }
}
</script>