<script>
  export default {
    data: () => ({
      titleEn: 'About Us',
      titleJa: '会社概要',
      rows: [ 
        { title: '会社名', value: 'Houston株式会社' }, { title: '代表者', value: '大関 亮' }, 
        { title: '所在地', value: '東京都千代田区九段北1-9-14' }, 
        { title: '設立日', value: '2022年9月1日' }, { title: '事業内容', value: '経営コンサルティング' }, 
      ]
    }),
  }
</script>

<template>
  <v-container fluid class="pa-0">
    <v-card>
    <v-img class="black--text align-middle" max-height="220" src="../assets/AboutUs2.png">
        <div class="pa-3">
          <div class="en-title">{{titleEn}}</div>
          <div class="ja-title">{{titleJa}}</div>
        </div>
    </v-img>
  </v-card>

    <v-container>  
      <v-row class="text-center" align="center" justify="center">
        <v-col class="mb-5" cols="11">
          <table class="company" v-for="(r, i) in rows" :key="i">
            <tr><td class="head pa-2">{{r.title}}</td><td class="value pa-2">{{r.value}}</td></tr>
          </table>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style >
  table.company{ width: 100% } 
  table.company td{
    border-bottom: 1px #ccc solid;
    text-align: start;
    font-size: 14px;
  }
  table.company td.head {
    background-color: #eee;
    width: 25%;
  }
  table.company td.value {
    width: 75%;
  }
  .middle{ display: flex; align-items: center; }
  .en-title{ 
    font-size: xxx-large;
    color: lavender;
    line-height: initial;
  }
  .ja-title{
    font-size: x-large;
    font-weight: bold;
  }
</style>